import React from 'react'
import Helmet from 'react-helmet'
import useSiteMetadata from '@blocks-helpers/useSiteMetadata'

const Seo = ({
  title,
  description,
  excerpt,
  meta,
  keywords,
  author,
  thumbnailUrl,
  siteUrl,
  ogImage
}) => {
  const site = useSiteMetadata()

  const social = (author && author.social) || site.social || []
  const twitter =
    social.find(s => s.name && s.name.toLowerCase() === 'twitter') || {}

  description = excerpt || description || site.description

 
 
  /**
   * Meta Tags
   */
  const thumb =(site.thumbnailUrl)

  const metaTags = [
    { itemprop: 'name', content: site.title },
    { itemprop: 'description', content: site.description },
    { name: 'description', content: site.description },
    { name: 'author', content: site.author },
    { name: 'keywords', content:site.keywords },
    { name: 'siteUrl', content: site.siteUrl },

    { property: 'og:title', content: title },
    { property: 'og:description', content: site.description },
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: site.name },
    { property: 'og:image', content: "social-share.jpg"},
  ]

  if (keywords && keywords.length > 0) {
    metaTags.push({ name: 'keywords', content: keywords.join(', ') })
  }

  if (meta) {
    metaTags.push(meta)
  }

  metaTags.push({ name: 'initial-scale', content: '1.0' })

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      title={title}
      titleTemplate={`%s | ${site.title}`}
      meta={metaTags}
    />
  )
}

export default Seo
